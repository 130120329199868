
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { StructuresNode } from 'types/interface/StructuresNode'
import useArchive from '@/composables/useArchive'
import ArchiveWrapper from '@/views/common/ArchiveWrapper.vue'

export default defineComponent({
  name: 'InterdisciplinaryArchive',

  components: {
    ArchiveWrapper,
  },

  setup() {
    const { archive, items } = useArchive()
    const route = useRoute()
    const archiveSlugPath = computed(
      () =>
        Array.isArray(route.params.slugPath) && route.params.slugPath.join('/'),
    )

    const cardActions = {
      type: 'interdisciplinary',
      action: (child: StructuresNode) => {
        return `/samarbeid-artikkel/${archiveSlugPath.value}/${child.slug}`
      },
    }

    return {
      archive,
      items,
      cardActions,
    }
  },
})
